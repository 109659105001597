<template>
  <v-row>
    <v-col cols="12" md="5">
      <h5 class="grey100--text text-body-2" v-text="'Given value'" />
      <p class="mb-0 text--secondary lh-1-4 text-body-2">
        ${{ fromItemsSum | toScrap }}
      </p>
    </v-col>
    <v-col cols="12" md="5">
      <h5 class="grey100--text text-body-2" v-text="'Received value'" />
      <p class="mb-0 text--secondary lh-1-4 text-body-2">
        ${{ toItemsSum | toScrap }}
      </p>
    </v-col>
    <v-col cols="12" md="2">
      <h5 class="grey100--text text-body-2" v-text="'Fee'" />
      <p class="mb-0 text--secondary lh-1-4 text-body-2">
        ${{ fee | toScrap }}
      </p>
    </v-col>
    <v-col cols="12" md="6">
      <h5 class="grey100--text text-body-2 mb-1">
        <span v-text="'Items given'" />
        <span class="ml-1 blue300--text">(x{{ fromItems.length }})</span>
      </h5>
      <div class="d-flex flex-wrap">
        <GameItem
          v-for="(item, i) in fromItems"
          :key="i" :item="item"
          no-item-color
        />
      </div>
    </v-col>
    <v-col cols="12" md="6">
      <h5 class="grey100--text text-body-2 mb-1">
        <span v-text="'Items received'" />
        <span class="ml-1 blue300--text">(x{{ toItems.length }})</span>
      </h5>
      <div class="d-flex flex-wrap">
        <GameItem
          v-for="(item, i) in toItems"
          :key="i" :item="item"
          no-item-color
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    itemdb: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fromItemsSum() {
      return this.transaction.data?.fromItemsSum ?? 0
    },
    toItemsSum() {
      return this.transaction.data?.toItemsSum ?? 0
    },
    fee() {
      return this.transaction.data?.fee ?? 0
    },
    fromItems() {
      const { itemdb } = this
      const { fromItems } = this.transaction.data

      return fromItems?.map(name => ({
        ...itemdb[name],
        name,
        amount: 1,
      })) ?? []
    },
    toItems() {
      const { itemdb } = this
      const { toItems } = this.transaction.data

      return toItems?.map(name => ({
        ...itemdb[name],
        name,
        amount: 1,
      })) ?? []
    },
  },
}
</script>

<style lang="scss" scoped>
  .game-item {
    .v-image ::v-deep .v-responsive__content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .item-amount {
      text-shadow: 0 0 2px black;
      user-select: none;
    }
  }
</style>
